import Home from './components/home/Home'



function App(){

    return (<Home/>)
}

export default App;
